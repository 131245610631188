import React from 'react';
import './App.css';
import './styles.scss'
import Gallery from './components/Gallery';

import HeadImage from './components/HeadImage';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
        data : null,
        loading: true
    }
  }
  componentDidMount() {
    fetch(`/config.json`, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }

    })
      .then(response => response.json())
      .then(data => this.setState({ 
        data : data,
        loading: false 
      }));
  }

  

  render() {
    if(this.state.loading){
      return (<header><h1>Loading...</h1></header>)
    } else {
      return (
        <div className="App">
          <nav className="site-header sticky-top py-1  font-weight-bold shadow-sm">
            <div className="container text-center display-5">
              <div className="">
                <div className="display-5 text-uppercase"><img src="/images/address.png" alt={this.state.data.display_address} /></div>
                <div className="display-6"><a href={"mailto:"+this.state.data.agent.email}><i className="far fa-envelope"></i> {this.state.data.agent.email}</a> <span className="d-none d-sm-inline"> |</span> <span className="d-none d-sm-inline"><i className="fas fa-phone"></i> {this.state.data.agent.phone}</span><span className="d-sm-block d-md-none"><br /><a href={"tel:"+this.state.data.agent.phone}><i className="fas fa-phone"></i> {this.state.data.agent.phone}</a></span></div>
              </div>
            </div>
          </nav>
          <HeadImage config_data={this.state.data} />
         


          <div className="position-relative overflow-hidden p-md-3 m-md-1 text-center">
            <div className="col-md-8 p-lg-8 mx-auto my-5"> 
              <h5 className="display-4 font-weight-bold">{this.state.data.display_address}</h5>
              <h4 className="display-5 font-weight-light text-muted">{this.state.data.full_address.city}, {this.state.data.full_address.state} {this.state.data.full_address.zipcode}</h4>
              <h6 className="display-4 text-secondary">{this.state.data.property.listing_price}</h6>
            </div>
          </div>

          { this.state.data.opens.length > 0 ?(
            <div className="container open-hold text-center">
              <div className="opens">
                <h3>Open House</h3>
                  { this.state.data.opens.map(({item, id}) =>
                      <div className="p-2 font-weight-bold text-primary" role="alert" key={id}>{item}</div>
                  ) }
              </div>
            </div>
          ) : null }

          
          
          <div className="position-relative overflow-hidden text-center">
            <div className="col-md-8 mx-auto my-5 blurb"> 
              <p className="pb-5">{this.state.data.property.blurb}</p>
              <ul>
                { this.state.data.property.ammenities.map(({item, id}) =>
                    <li key={id}>{item}</li>
                ) }
              </ul>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-6 col-md-3  text-center overflow-hidden mb-3npm">
                <div className="mx-auto py-3 bg-light rounded">
                <div className="icon-holder">
                  <i className="fas fa-bed"></i>
                </div>
                <div className="my-0">
                    <p className="lead">{this.state.data.property.bedrooms} Bedrooms</p>
                </div>
                </div>
              </div>

              <div className="col-6 col-md-3   px-3 text-center overflow-hidden mb-3">
                <div className="mx-auto py-3 bg-light rounded">
                <div className="icon-holder">
                <i className="fas fa-bath"></i>
                </div>
                <div className="my-0">
                    <p className="lead">{this.state.data.property.baths} bath</p>
                </div>
                </div>
              </div>

              <div className="col-6 col-md-3 text-center overflow-hidden mb-3">
                <div className="mx-auto py-3 bg-light rounded">
                <div className="icon-holder">
                  <i className="fas fa-ruler-combined"></i>
                </div>
                <div className="my-0">
                    <p className="lead">{this.state.data.property.sq_feet} Sq Ft</p>
                </div>
                </div>
              </div>

              <div className="col-6 col-md-3 text-center overflow-hidden mb-3">
                <div className="mx-auto py-3 bg-light rounded">
                  <div className="icon-holder">
                    <i className="fas fa-tree"></i>
                  </div>
                  <div className="my-0">
                      <p className="lead">{this.state.data.property.lot_size} sq ft lot</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          
          <div className="container">
            <div className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
              <Gallery config_data={this.state.data} />
            </div>

          <div className="row">
            <div className="col-6 col-md-6  text-center overflow-hidden mb-3npm">
              <div className="mx-auto py-3 bg-light rounded">
              <div className="icon-holder">
                <i className="fas fa-hammer"></i>
              </div>
              <div className="my-0">
                  <p className="lead">Built in {this.state.data.property.built_in}</p>
              </div>
              </div>
            </div>

            <div className="col-6 col-md-6   px-3 text-center overflow-hidden mb-3">
              <div className="mx-auto py-3 bg-light rounded">
              <div className="icon-holder">
              <i className="fas fa-home"></i>
              </div>
              <div className="my-0">
                  <p className="lead">RMLS# {this.state.data.property.rmls}</p>
              </div>
              </div>
            </div>
          </div>
          
          {/* <div className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
              <iframe
                title={this.state.data.display_address}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.1034382563294!2d-122.9200516844404!3d45.52812397910173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f8ed90dd6b7%3A0xa2805a6c840bd9d7!2s711%20NE%2061st%20Ct%2C%20Hillsboro%2C%20OR%2097124!5e0!3m2!1sen!2sus!4v1568330443932!5m2!1sen!2sus"
                width="100%"
                height="450"
                allowFullScreen>
              </iframe>
          </div>

            <div className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
              <iframe width="100%" title={this.state.data.display_address} height="685" src="https://www.youtube.com/embed/5i4fngqdO80"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div> */}
            <div className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
              <iframe
                title={this.state.data.display_address}
                src={this.state.data.google.maps}
                width="100%"
                height="450"
                allowFullScreen>
              </iframe>
          </div>

            <div className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
              <iframe width="100%" title={this.state.data.display_address} height="685" src={this.state.data.google.youtube}  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

          </div>

          <footer className="position-relative overflow-hidden p- p-md-3 m-md-1 text-center">
            <div className="row justify-content-md-center mb-5">
              <div className=" col col-12 col-lg-2">
                <img src="/images/face.jpg" className="img-fluid" alt="Aubrey Martin Real Estate Agent Portland Oregon" />
              </div>
              <div className=" col text-center col-md-7 display-5 font-weight-bold pt-4">
              <div className="row justify-content-md-center">
                <div className="col col-md-9">
                  <img src="/images/Aubrey_logo.png" className="img-fluid" alt="Aubrey Martin Real Estate Agent Portland Oregon" />
                </div>
              </div>
                <h3><a href={"mailto:"+this.state.data.agent.email}>{this.state.data.agent.email}</a> | <span className="d-none d-sm-inline"><i className="fas fa-phone"></i> {this.state.data.agent.phone}</span><span className="d-sm-block d-md-none"><a href={"tel:"+this.state.data.agent.phone}><i className="fas fa-phone"></i> {this.state.data.agent.phone}</a></span></h3>
              </div>
            </div>
          </footer>
        </div>
      );
    }
  }
}

export default App;
